<template>
    <Breadcrumb page-title="Configure Agent Interface" page-section="Settings "> </Breadcrumb>
    <section class="content">
           <SegmentLevelSettings></SegmentLevelSettings>
    </section>
 </template>
 <style src="@vueform/toggle/themes/default.css"></style>
 <script>
 // @ is an alias to /src
 import Breadcrumb from '../components/Breadcrumb.vue';
 import DetailsSettings from '../components/DetailsSettings.vue';
 import SegmentLevelSettings from '../components/SegmentLevelSettings.vue';

 import Toggle from '@vueform/toggle'
 export default {
   name: 'Configure Agent Interface',
   components: {
     Breadcrumb,
     Toggle,
     SegmentLevelSettings
   },
   data: function(){
       return{
         segment1 : true,
         segment2 : false,
         segment3 : false,
         segment4 : false,
         segment7: true,
         segment8:true,
         segment10:true,
         segment11:true,
         choosedWebsite : false
       }
          
   },
   ready () {
     $(document).ready(function ($) {
     })
   },
    methods: {
     toggleSegmentCollapsedState(i) {
       this['segment'+i] = !this['segment'+i] 
     }
   }
 }
 </script>
 