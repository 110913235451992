<template>
  <div class="row">
    <div class="col-md-12">
       <div class="box box-info">
            <div class="box-body">
               <div class="row">
                    <div class="col-md-6">
                        <div class="row">
                            <div class="form-group">
                                <label class="col-12 control-label text-left">Segment Name</label>
                                <div iservice-id="Name" class="col-12">
                                <input type="text" class="form-control">
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-12 control-label text-left">Description</label>
                                <div iservice-id="Description" class="col-12"><textarea class="form-control" rows="3"></textarea></div>
                            </div>
                            <div class="form-group">
                                <label class="col-12 control-label text-left">Segment Parent <span><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                                <div iservice-id="Name" class="col-12">
                                    <Multiselect
                                        v-model="value"
                                        placeholder="-None-"
                                        label="name"
                                        :groups="false"
                                        :searchable="true"
                                        :options="[
                                             { value: 'iservice', name: 'iservice',level:'0'},
                                                { value: '1to1service', name: '1to1service',level:'0'}
                                            
                                        ]"
                                        >
                                        <template  v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                </div>
                            </div>
                            <div class="form-group">
                                <label class="col-12 control-label text-left"> 
                                <Toggle v-model="managemessage"/> This Segment Manages Interactions (Standard Segment)<span><i class="fa fa-question-circle text-info help-icon"></i></span></label> 
                            </div>
                        </div>
                    </div>

                    <div class="col-md-6" v-if="managemessage">
                       <div class="row">
                         <div class="col-md-12">
                            <div class="form-group bg-group">
                                <label class="control-label text-left">Default Notifications <span><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                                <div class="form-group group-bg group-input detail-g-borderbottom">
                                    <label class="col-md-12 control-label text-left group-input-label">Message Acknowledgment</label>
                                    <div class="col-md-12">
                                    <Multiselect
                                        v-model="Acknowledgment"
                                        placeholder="-None-"
                                        label="name"
                                        :groups="false"
                                        :searchable="true"
                                        :options="[
                                             { value: 'Abuse auto response new', name: 'Abuse auto response new',level:'0'},
                                            
                                        ]"
                                        >
                                        <template  v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                    </div>
                                </div>
                                 <div class="form-group group-bg group-input detail-g-borderbottom detail-g-bordertop">
                                    <label class="col-md-12 control-label text-left group-input-label">Contact Creation</label>
                                    <div class="col-md-12">
                                    <Multiselect
                                        v-model="account"
                                        placeholder="-None-"
                                        label="name"
                                        :groups="false"
                                        :searchable="true"
                                        :options="[
                                             { value: 'Account creation new', name: 'Account creation new',level:'0'},
                                            
                                        ]"
                                        >
                                        <template  v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                    </div>
                                </div>
                                 <div class="form-group group-bg group-input detail-g-borderbottom detail-g-bordertop">
                                    <label class="col-md-12 control-label text-left group-input-label">Agent Notification</label>
                                    <div class="col-md-12">
                                    <Multiselect
                                        v-model="agentnotification"
                                        placeholder="-None-"
                                        label="name"
                                        :groups="false"
                                        :searchable="true"
                                        :options="[
                                             { value: 'Agent Notify - Email Reply', name: 'Agent Notify - Email Reply',level:'0'},
                                            
                                        ]"
                                        >
                                        <template  v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                    </div>
                                </div>
                                 <div class="form-group group-bg group-input detail-g-bordertop">
                                    <label class="col-md-12 control-label text-left group-input-label">Knowledge Base Article Update</label>
                                    <div class="col-md-12">
                                    <Multiselect
                                        v-model="Knowledgebase"
                                        placeholder="-None-"
                                        label="name"
                                        :groups="false"
                                        :searchable="true"
                                        :options="[
                                             { value: 'Find Answer Update', name: 'Find Answer Update',level:'0'},
                                            
                                        ]"
                                        >
                                        <template  v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                    </div>
                                </div>
                            </div> 
                            
                          </div>
                       <div class="col-md-12 mt-3">
                           <div class="form-group">
                                <label class="ontrol-label text-left">Segment Default Service Level <span><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                                <div iservice-id="Name">
                                   <Multiselect
                                        v-model="segementService"
                                        placeholder="-None-"
                                        label="name"
                                        :groups="false"
                                        :searchable="true"
                                        :options="[
                                             { value: 'Tenant Default', name: 'Tenant Default',level:'0'},
                                            
                                        ]"
                                        >
                                        <template  v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                </div>
                            </div>
                             <div class="form-group">
                                <label class="control-label text-left">Segment Default Mailbox <span><i class="fa fa-question-circle text-info help-icon"></i></span></label>
                                <div iservice-id="Name" class="">
                                 <Multiselect
                                        v-model="segementdefault"
                                        placeholder="-None-"
                                        label="name"
                                        :groups="false"
                                        :searchable="true"
                                        :options="[
                                             { value: 'iService Support', name: 'iService Support',level:'0'},
                                            
                                        ]"
                                        >
                                        <template  v-slot:option="{ option }">
                                        <span v-bind:class="'op-level-' + option.level"> {{ option.name }} </span>
                                        </template>
                                    </Multiselect>
                                </div>
                            </div>
                             <div class="form-group">
                                <label class="control-label text-left"> 
                                <Toggle v-model="queue"/>  Queue all unassigned interactions for same contact with Get Next<span><i class="fa fa-question-circle text-info help-icon"></i></span></label> 
                            </div>
                       </div>
                       </div>
                       
                    </div>
               </div>
            </div>
       </div>
    </div>
  </div>
</template>
 <style src="@vueform/toggle/themes/default.css"></style>
<script>
import Toggle from '@vueform/toggle'
import Multiselect from '@vueform/multiselect'
export default {
  name: 'DetailsContent',
  components: {
    Toggle,
    Multiselect
  },
 data() {
      return{
        managemessage : false,
        queue : false
      }
  },
  methods: {
   
  }
  
}
</script>